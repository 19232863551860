<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently">
          <span style="font-size: 15px">需审批:</span>
          <span style="color: red"
                v-show="apprNeedList.length > 0">{{ apprNeedList.length > 0 ? apprNeedList[0].count : 0 }}条</span>
          <span :class="{ vg_rotate: rotateFlag, 'el-icon-refresh': true }" @click="loadingChange"/>
          <i>(最近10条)</i>
        </span>
        <el-button type="text" class="vd_card_button" @click="goAllApr()">查看全部</el-button>
      </div>
      <div class="card_body">
        <div v-if="apprNeedList.length > 0">
          <el-card shadow="hover" v-for="(item, index) in apprNeedList" :key="index" class="vg_mt_8"
                   v-loading="loading">
            <div>
              <el-form size="mini" label-width="100px" :label-position="labelPosition">
                <el-row>
                  <el-col>
                    <el-form-item label="审批模块：" prop="perm_name">
                      <span> {{ item.perm_name }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="审批时间：" prop="update_time">
                      {{ helper.toTime(item.create_time) }}
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item label="发起人说明：" prop="appr_inst_info">
                      <el-link type="primary" @click="goAppr(item.appr_inst_url)" :underline="false" class="vg_pointer">
                        {{ item.appr_inst_info }}
                      </el-link
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-card>
        </div>
        <div class="el-icon-warning-outline notVal" v-else>暂无审批</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {get} from '@api/request';
import {apprAPI} from '@api/modules/appr';
import {userAPI} from '@/api/modules/user';
import {debounce} from 'lodash';
import bus from '@/components/common/bus';

export default {
  name: 'boardLeft',
  data() {
    return {
      labelPosition: 'left',
      apprNeedList: [],
      loading: true,
      rotateFlag: false
    };
  },
  created() {
  },
  mounted() {
    this.initData();
    this.$EventBus.$on('submit', res => {
      this.initData();
    });
    bus.$on('onmessage', res => {
      if (res === '提交1') {
        this.initData();
      }
    });
  },
  watch: {
    $route({name}, from) {
      if (name === 'dashboard') {
        this.getApprove();
      }
    },
    '$store.state.reGetDashBoardData'(newValue, oldValue) {
      this.getApprove();
    }
  },
  methods: {
    initData() {
      this.getApprove();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    // 获取待审批记录 节流
    getApprove: debounce(function () {
      get(apprAPI.getIApprove)
          .then(res => {
            if (res.data.code === 0) {
              this.$data.loading = false;
              this.$data.apprNeedList = res.data.data;
            } else if (res.data.code === 3) {
              this.deleteCookie();
              this.$router.push('/login');
            } else {
              this.$message({message: res.data.msg, type: 'error'});
            }
          })
          .catch(res => {
            this.$message({message: res.data.msg, type: 'error'});
          });
    }, 500),
    goAppr(val) {
      this.jump(val);
    },
    goAllApr() {
      this.jump('/toApproved_all');
    },
    // 删除cookie
    deleteCookie() {
      get(userAPI.userLogout)
          .then(res => {
            if (res.data.code === 0) {
              this.$cookies.remove('userInfo');
              this.$cookies.remove('push');
            }
          })
          .catch(() => {
          });
    },
    // 刷新被审批的人数
    loadingChange() {
      this.loading = true;
      this.rotateFlag = true;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper-box ::v-deep .el-card__header {
  padding: 8px 20px;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
}

.card_body {
  padding-right: 10px;
  overflow: auto;
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}

.card_body {
  position: relative;
}

.notVal {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
}

.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
    margin-top: 5px;
  }

  span {
    margin: 0 10px;
    margin-top: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      color: blue;
    }
  }
}
</style>
